.home20230123-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home20230123-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home20230123-container01 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url("/external/ameya_gayatri_1-1500h.jpg");
}
.home20230123-container02 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home20230123-hero-heading {
  color: #eceaea;
  font-size: 3em;
  max-width: 800px;
  text-align: center;
  font-family: "GFS Didot";
  font-weight: 700;
  line-height: 150%;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home20230123-hero-sub-heading {
  color: rgb(243, 238, 238);
  font-size: 1.2em;
  text-align: center;
  font-family: "GFS Didot";
}
.home20230123-event {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home20230123-container03 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home20230123-image {
  flex: 1;
  width: 239px;
  height: 118px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home20230123-container04 {
  flex: 0 0 auto;
  width: 711px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home20230123-text {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-container05 {
  flex: 0 0 auto;
  width: 711px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home20230123-text01 {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-venue {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home20230123-banner {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home20230123-text02 {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text05 {
  font-size: 1.5em;
  font-family: "Montserrat";
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home20230123-text06 {
  display: none;
  font-size: 1.5em;
  font-family: "Montserrat";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home20230123-text07 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-iframe {
  width: 750px;
  height: 500px;
}
.home20230123-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home20230123-hero-button1 {
  color: rgb(0, 0, 0);
  font-size: 1em;
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home20230123-hero-button1:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home20230123-save-the-date {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home20230123-banner1 {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-white);
}
.home20230123-text13 {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text14 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text18 {
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-btn-group1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home20230123-hero-button11 {
  color: rgb(0, 0, 0);
  font-size: 1em;
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home20230123-hero-button11:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home20230123-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home20230123-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home20230123-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home20230123-text26 {
  font-size: 1em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text27 {
  display: none;
}
.home20230123-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home20230123-icon {
  width: 24px;
  height: 24px;
  display: none;
  margin-right: var(--dl-space-space-twounits);
}
.home20230123-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home20230123-icon4 {
  width: 24px;
  height: 24px;
  display: none;
}
.home20230123-save-the-date-2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home20230123-container07 {
  width: 80%;
  height: 120vh;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url("https://play.teleporthq.io/static/svg/placeholders/no-image.svg");
  background-position: top;
}
.home20230123-container08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 30%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
  border-color: #828282;
  border-style: groove;
  border-width: 3px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgba(228, 225, 225, 0.81);
}
.home20230123-text28 {
  display: none;
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text29 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text37 {
  font-size: 1.2em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-btn-group2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home20230123-hero-button12 {
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home20230123-hero-button12:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home20230123-save-the-date-21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home20230123-container09 {
  width: 80%;
  height: 120vh;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url("https://play.teleporthq.io/static/svg/placeholders/no-image.svg");
  background-position: top;
}
.home20230123-container10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 30%;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
  border-color: #828282;
  border-style: groove;
  border-width: 3px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgba(228, 225, 225, 0.81);
}
.home20230123-text47 {
  display: none;
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text48 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-text56 {
  font-size: 1.2em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home20230123-btn-group3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home20230123-hero-button13 {
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home20230123-hero-button13:hover {
  color: #ffffff;
  background-color: #437b41;
}
@media(max-width: 991px) {
  .home20230123-container01 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container02 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home20230123-hero-heading {
    text-align: center;
  }
  .home20230123-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home20230123-container03 {
    width: 100%;
    align-items: center;
  }
  .home20230123-container07 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container08 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home20230123-container09 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container10 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home20230123-container01 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container02 {
    height: auto;
    align-self: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home20230123-hero-sub-heading {
    font-size: 1.5em;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home20230123-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home20230123-text05 {
    font-size: 1.5em;
    font-family: Montserrat;
    font-weight: bold;
  }
  .home20230123-text06 {
    display: none;
  }
  .home20230123-iframe {
    display: none;
  }
  .home20230123-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home20230123-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home20230123-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home20230123-container06 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home20230123-text26 {
    font-size: 1em;
  }
  .home20230123-text27 {
    display: none;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home20230123-container07 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container08 {
    height: auto;
    align-self: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home20230123-container09 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home20230123-container10 {
    height: auto;
    align-self: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
}
@media(max-width: 479px) {
  .home20230123-container01 {
    height: 100vh;
    background-size: cover;
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
    background-position: right;
  }
  .home20230123-container02 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 8em;
  }
  .home20230123-hero-heading {
    font-size: 1.7em;
    max-width: 100%;
  }
  .home20230123-hero-sub-heading {
    font-size: 1.1em;
  }
  .home20230123-container04 {
    width: 100%;
  }
  .home20230123-text {
    width: auto;
    font-size: 1.75em;
    font-weight: bold;
  }
  .home20230123-container05 {
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
  }
  .home20230123-text01 {
    width: auto;
    font-size: 1.75em;
    font-weight: bold;
  }
  .home20230123-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home20230123-text02 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home20230123-text05 {
    width: auto;
    font-size: 1.2em;
    font-weight: bold;
  }
  .home20230123-text06 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home20230123-text07 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .home20230123-iframe {
    display: none;
  }
  .home20230123-btn-group {
    flex-direction: column;
  }
  .home20230123-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home20230123-text13 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home20230123-text14 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home20230123-text18 {
    width: auto;
    font-size: 1em;
  }
  .home20230123-btn-group1 {
    flex-direction: column;
  }
  .home20230123-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home20230123-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home20230123-container06 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home20230123-text26 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home20230123-text27 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home20230123-container07 {
    height: 100vh;
    background-size: cover;
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
    background-position: right;
  }
  .home20230123-container08 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 8em;
  }
  .home20230123-text28 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home20230123-text29 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home20230123-text37 {
    width: auto;
    font-size: 1em;
  }
  .home20230123-btn-group2 {
    flex-direction: column;
  }
  .home20230123-container09 {
    height: 100vh;
    background-size: cover;
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
    background-position: right;
  }
  .home20230123-container10 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 8em;
  }
  .home20230123-text47 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home20230123-text48 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home20230123-text56 {
    width: auto;
    font-size: 1em;
  }
  .home20230123-btn-group3 {
    flex-direction: column;
  }
}
