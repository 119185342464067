.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container1 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url("/external/ameya_gayatri_1-1500h.jpg");
}
.home-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-hero-heading {
  color: #eceaea;
  font-size: 3em;
  max-width: 800px;
  text-align: center;
  font-family: "GFS Didot";
  font-weight: 700;
  line-height: 150%;
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-hero-sub-heading {
  color: rgb(243, 238, 238);
  font-size: 1.2em;
  text-align: center;
  font-family: "GFS Didot";
}
.home-event {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  flex: 1;
  width: 239px;
  height: 118px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container4 {
  flex: 0 0 auto;
  width: 711px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container5 {
  flex: 0 0 auto;
  width: 711px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text01 {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-venue {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner {
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-text02 {
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text05 {
  font-size: 1.5em;
  font-family: "Montserrat";
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text06 {
  display: none;
  font-size: 1.5em;
  font-family: "Montserrat";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text07 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-iframe {
  width: 750px;
  height: 500px;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home-hero-button1 {
  color: rgb(0, 0, 0);
  font-size: 1em;
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home-hero-button1:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home-save-the-date {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container6 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-image: url('https://res.cloudinary.com/dgcaadfgd/image/upload/f_auto,q_auto/save_the_date.jpg');
}
.home-banner1 {
  width: 50%;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-white);
}
.home-text13 {
  display: none;
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text14 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text18 {
  font-size: 1.2em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-btn-group1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home-hero-button11 {
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  transition: 0.3s;
  font-family: "Montserrat";
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  background-color: rgba(20, 116, 135, 0);
}
.home-hero-button11:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home-save-the-date-mobile {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  align-items: center;
  justify-content: center;
}
.home-banner2 {
  width: 50%;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-white);
}
.home-text26 {
  display: none;
  font-size: 2em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text27 {
  display: none;
  font-size: 1em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text31 {
  font-size: 1.2em;
  text-align: center;
  font-family: "Montserrat";
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-btn-group2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.home-hero-button12 {
  color: rgb(0, 0, 0);
  font-size: 1.2em;
  transition: 0.3s;
  font-family: "Montserrat";
  background-color: rgba(20, 116, 135, 0);
}
.home-hero-button12:hover {
  color: #ffffff;
  background-color: #437b41;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container7 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text39 {
  font-size: 1em;
  font-family: "GFS Didot";
  font-weight: bold;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text40 {
  display: none;
}
.home-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon {
  width: 24px;
  height: 24px;
  display: none;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon4 {
  width: 24px;
  height: 24px;
  display: none;
}
@media(max-width: 991px) {
  .home-container1 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home-container2 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container3 {
    width: 100%;
    align-items: center;
  }
  .home-text18 {
    font-size: 1em;
  }
  .home-hero-button11 {
    font-size: 1em;
  }
  .home-text31 {
    font-size: 1em;
  }
  .home-hero-button12 {
    font-size: 1em;
  }
}
@media(max-width: 767px) {
  .home-container1 {
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
  }
  .home-container2 {
    height: auto;
    align-self: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-hero-sub-heading {
    font-size: 1.5em;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text05 {
    font-size: 1.5em;
    font-family: Montserrat;
    font-weight: bold;
  }
  .home-text06 {
    display: none;
  }
  .home-iframe {
    display: none;
  }
  .home-banner1 {
    display: none;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-save-the-date-mobile {
    display: flex;
  }
  .home-banner2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container7 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text39 {
    font-size: 1em;
  }
  .home-text40 {
    display: none;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-container1 {
    height: 100vh;
    background-size: cover;
    background-image: url("/external/ameya_gayatri_vertical-1500w.jpg");
    background-position: right;
  }
  .home-container2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 8em;
  }
  .home-hero-heading {
    font-size: 1.7em;
    max-width: 100%;
  }
  .home-hero-sub-heading {
    font-size: 1.1em;
  }
  .home-container4 {
    width: 100%;
  }
  .home-text {
    width: auto;
    font-size: 1.75em;
    font-weight: bold;
  }
  .home-container5 {
    width: 100%;
    padding-top: var(--dl-space-space-threeunits);
  }
  .home-text01 {
    width: auto;
    font-size: 1.75em;
    font-weight: bold;
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text02 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home-text05 {
    width: auto;
    font-size: 1.2em;
    font-weight: bold;
  }
  .home-text06 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home-text07 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .home-iframe {
    display: none;
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-save-the-date {
    position: relative;
  }
  .home-container6 {
    flex: 1;
    width: 90%;
    height: calc(100vw * 4 / 3 * 0.9);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text13 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home-text14 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home-text18 {
    width: auto;
    font-size: 1em;
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-banner2 {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text26 {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
  }
  .home-text27 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home-text31 {
    width: auto;
    font-size: 1em;
  }
  .home-btn-group2 {
    flex-direction: column;
  }
  .home-hero-button12 {
    color: rgb(0, 0, 0);
    width: auto;
    font-size: 1em;
    font-family: Montserrat;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgba(20, 116, 135, 0);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container7 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text39 {
    width: auto;
    font-size: 1em;
    font-weight: bold;
  }
  .home-text40 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
