.event-name-and-time-simple-feature-card {
  width: 678px;
  height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  animation-name: fadeIn;
  padding-bottom: var(--dl-space-space-halfunit);
  animation-delay: 0s;
  justify-content: flex-start;
  background-color: transparent;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.event-name-and-time-simple-container {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event-name-and-time-simple-text {
  width: var(--dl-size-size-xlarge);
  font-size: 1.5em;
  font-family: "Montserrat";
  font-weight: bold;
}
.event-name-and-time-simple-text1 {
  font-size: 1.5em;
  font-family: "Montserrat";
}











.event-name-and-time-simple-root-class-name11 {
  display: none;
}













.event-name-and-time-simple-root-class-name25 {
  display: none;
}









@media(max-width: 767px) {
  .event-name-and-time-simple-feature-card {
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .event-name-and-time-simple-feature-card {
    width: auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .event-name-and-time-simple-container {
    left: 25px;
    width: auto;
    position: absolute;
  }
  .event-name-and-time-simple-text {
    width: var(--dl-size-size-medium);
    font-size: 1.2em;
  }
  .event-name-and-time-simple-text1 {
    font-size: 1.2em;
  }
}
